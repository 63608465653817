import React,{Fragment} from 'react'
import './courses.css'
import CardCourse from './Card/CardCourse'
import { CircularProgress } from '@mui/material'

const Courses = ({CoursesData}) => {
  return (
    <div className="courses">
      
    <div className="page-heading">
     <h1> Courses</h1>
     </div>
  <div className="courses-container">
     
  {CoursesData?.length === 0 ? <CircularProgress/> : 
        CoursesData.map((c, key) => (
          <Fragment key={key}>
            <CardCourse course={c} />
          </Fragment>
        ))
      }
    </div>
  </div>
  )
}

export default Courses