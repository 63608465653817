import './App.css';
import * as React from 'react';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Routing from './Components/Routes';
import axios from 'axios';

import { API_URL } from './api';

function App() {
    const [ClassesData, setClassesData] = React.useState([]);
    const [CoursesData, setCoursesData] = React.useState([]);
    const [TeachersData, setTeachersData] = React.useState([]);
    const [ResourcesData, setResourcesData] = React.useState([]);
    const [classTimetable, setClassTimetable] = React.useState([]);
    const [timeTableTitle, setTimeTableTitle] = React.useState([]);
    const [TestimonialsData, setTestimonialsData] = React.useState([]);
    const [SettingsData, setSettingsData] = React.useState([]);
    const [aboutUsData, setAboutUsData] = React.useState([]);
    const [faqDetailsData, setFaqDetailsData] = React.useState([]);
    const [faqData, setFaqData] = React.useState([]);
    const [teamPageDetails, setTeamPageDetails] = React.useState({});
    const [GalleryData, setGalleryData] = React.useState([]);
    React.useEffect(() => {

        // get classes
        axios.get(`${API_URL}/api/classes?subject=english`).then((response) => {
            setClassesData(response.data);
        });

        // get Gallery
        axios.get(`${API_URL}/api/gallery?subject=english`).then((response) => {
            setGalleryData(response.data);
        });
        // get courses
        axios.get(`${API_URL}/api/courses?subject=english`).then((response) => {
            setCoursesData(response.data);
        });
        // get resources
        axios.get(`${API_URL}/api/resources?subject=english`).then((response) => {
            setResourcesData(response.data);
        });
        // get teachers
        axios.get(`${API_URL}/api/teachers?subject=english`).then((response) => {
            setTeachersData(response.data);
        });
        // get timetable
        axios
            .get(`${API_URL}/api/timetable/?subject=english`)
            .then((res) => {
                setClassTimetable(res.data);
            })
            .catch((error) => console.log(error));
        axios
            .get(`${API_URL}/api/timetable/title`)
            .then((res) => {
                setTimeTableTitle(res.data);
            })
            .catch((error) => console.log(error));
        // get testimonial
        axios
            .get(`${API_URL}/api/testimonial?subject=english`)
            .then((res) => {
                setTestimonialsData(res.data);
            })
            .catch((error) => console.log(error));

        axios
            .get(`${API_URL}/api/settings/`)
            .then((res) => {
                setSettingsData(res.data);
            })
            .catch((error) => console.log(error));

        // get about
        axios
            .get(`${API_URL}/api/about-us?subject=english`)
            .then((res) => {
                res.data.length && setAboutUsData(res.data[0]);
            })
            .catch((error) => console.log(error));

        // get FAQ-details
        axios
            .get(`${API_URL}/api/FAQ-detail`)
            .then((res) => {
                setFaqDetailsData(res.data);
            })
            .catch((error) => console.log(error));

        // get FAQ
        axios
            .get(`${API_URL}/api/FAQ?subject=english`)
            .then((res) => {
                setFaqData(res.data);
            })
            .catch((error) => console.log(error));

        // get team page detail
        axios
            .get(`${API_URL}/api/team-detail`)
            .then((res) => {
                setTeamPageDetails(res.data);
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <div className="App">
            <nav>
                <Navbar />
            </nav>
            <header className="header-component">
                <Routing
                    ClassesData={ClassesData}
                    GalleryData={GalleryData}
                    CoursesData={CoursesData}
                    ResourcesData={ResourcesData}
                    TeachersData={TeachersData}
                    classTimetable={classTimetable}
                    TestimonialsData={TestimonialsData}
                    SettingsData={SettingsData}
                    TimeTableTitleData={timeTableTitle}
                    AboutUsData={aboutUsData}
                    faqDetailsData={faqDetailsData}
                    faqData={faqData}
                    teamPageDetails={teamPageDetails}
                />
            </header>
            <footer>
                <Footer />
            </footer>
        </div>
    );
}

export default App;
