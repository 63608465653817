import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import './cardresource.css';

export default function CardResource(props) {
    return (
        <Card sx={{ width: 300, margin: 3 }}>
            <CardContent style={{ borderTop: `8px solid ${props.resource.background}`, borderBottom: '1px solid #D3D3D3' }}>
                <Typography
                    minHeight="60px"
                    variant="h6"
                    component="div"
                    sx={{ fontFamily: 'Open Sans', fontSize: '18px', fontWeight: '600' }}
                >
                    {props.resource.title}
                </Typography>
            </CardContent>

            <CardActions sx={{ p: 2, justifyContent: 'flex-end' }}>
                <Link to={props?.resource?._id}>
                    <Button
                        sx={{ ':hover': { opacity: 0.9 } }}
                        size="small"
                        style={{
                            backgroundColor: '#73D5F0',
                            color: 'white',
                            fontFamily: 'Open Sans',
                            fontWeight: 'bold',
                            padding: '5px 24px',
                            borderRadius: '5px',
                        }}
                    >
                        View
                    </Button>
                </Link>
            </CardActions>
        </Card>
    );
}
