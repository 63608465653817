import * as React from 'react';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import './resourcedetailcard.css';
import ReactHtmlParser from 'react-html-parser';

const ResourceDetailsCard = (props) => {
    return (
        <div className="resourceDetails">
            <div className="page-heading">
                <h1> Resources</h1>
            </div>
            <div className="resourceDetails-container">
                <Card sx={{ width: '90%', overflowWrap: 'anywhere', wordBreak: 'break-word', flexWrap: 'break-word' }}>
                    <CardContent sx={{ width: '90%', wrapWord: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary">
                            {ReactHtmlParser(props.resource.longDescription)}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default ResourceDetailsCard;
