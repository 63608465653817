import React, { useState } from 'react';
import './JoinTheTeam.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';

import { styled } from '@mui/material/styles';
import axios from 'axios';
import { API_URL } from '../../api';
import { useNavigate } from 'react-router-dom';
import { BorderAll } from '@mui/icons-material';
import { hover } from '@testing-library/user-event/dist/hover';
import Swal from 'sweetalert2';
import errorIcon from '../../assets/error-icon.png';
import successIcon from '../../assets/success-icon.png';
import { useEffect } from 'react';
import FileBase from 'react-file-base64';
import { MdCloudUpload } from 'react-icons/md';
import { Fab, Input, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
const recaptchaRef = React.createRef();

const CssTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: '#0099FF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0099FF',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0099FF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0099FF',
        },
    },
    // '& .MuiOutlinedInput-root:hover': {
    //     '& fieldset': {
    //         borderColor: '#FAECF5',
    //     },
    //     '&.Mui-focused fieldset': {
    //         borderColor: '#FAECF5',
    //     },
    // },

    minWidth: '95%',
    [theme.breakpoints.up('sm')]: {
        minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
        minWidth: '44ch',
        // minWidth: '50%',
    },
    [theme.breakpoints.up('lg')]: {
        minWidth: '50ch',
        // minWidth: '25%',
    },
}));

const JoinTeam = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [joinTeam, setjoinTeam] = React.useState({});
    const [mailValid, setMailValid] = React.useState('');

    const [child, setChild] = React.useState({
        fullName: '',
        email: '',
        telephone: '',
        cvFile: '',
        description: '',
    });

    const createChild = async (child) => {
        const text = child.fullName;
        let result = text.trim().length;
        if (result <= 0) {
            return Swal.fire({
                imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png',
                imageHeight: '100px',
                title: 'Oops...',
                text: 'Full Name is Reqired',
                confirmButtonColor: '#0099FF',
            });
        }
        if (!child.fullName || child.fullName == '') {
            return Swal.fire({
                imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png',
                imageHeight: '100px',
                title: 'Oops...',
                text: 'Full Name is Reqired',
                confirmButtonColor: '#0099FF',
            });
        }
        if (!child.email || child.email == '') {
            return Swal.fire({
                imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png',
                imageHeight: '100px',
                title: 'Oops...',
                text: 'Email is Reqired',
                confirmButtonColor: '#0099FF',
            });
        }
        if (child?.email || child.email != '') {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(child?.email)) {
                await axios
                    .post(API_URL + '/api/join-team', child)
                    .then((res) => {
                        // alert('Your message has been sent');
                        Swal.fire({
                            // position: 'top-end',
                            imageUrl: 'https://pixlok.com/wp-content/uploads/2021/12/Green-Tick-Icon-SVG-03vd.png',
                            imageHeight: '100px',
                            title: 'Thank you for your message.',
                            html: 'I will respond to you as soon as I can.' + '<br/>' + '---Meera Vasudeva---',
                            // text: ' I will respond to you as soon as I can. "<br/>" ---Meera Vasudeva---',
                            width: 600,
                            confirmButtonColor: '#0099FF',
                            customClass: {
                                icon: 'no-before-icon',
                            },
                        });
                        navigate('/');
                    })
                    // .catch((err) => alert('Your message could not be sent'));
                    .catch((err) => {
                        console.log(err.response.data.message);
                        Swal.fire({
                            imageUrl:
                                'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png',
                            imageHeight: '100px',
                            title: 'Oops...',
                            // text: 'Your message could not be sent!',
                            text: err?.response?.data?.message || 'Your message could not be sent!',
                            confirmButtonColor: '#0099FF',
                        });
                    });
            } else {
                Swal.fire({
                    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png',
                    imageHeight: '100px',
                    title: 'Oops...',
                    text: 'You are writing invalid email address!',
                    confirmButtonColor: '#0099FF',
                });
                return false;
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        recaptchaRef.current.execute();
        createChild(child);
    };

    useEffect(() => {
        // get join team page detail
        axios
            .get(`${API_URL}/api/join-team-title`)
            .then((res) => {
                setjoinTeam(res?.data);
            })
            .catch((error) => console.log(error));
    }, []);
    function onCaptchaChange(value) {
        // setVerified(true);
    }
    return (
        <div className="childprofile">
            <div className="page-heading">
                {/* <h1>Join The Team</h1> */}

                <h1>{joinTeam?.title}</h1>
            </div>
            <div className="childprofile-container">
                <Box
                    component="form"
                    className="boxx"
                    onSubmit={handleSubmit}
                    sx={{
                        '& .MuiTextField-root': { m: 1, maxWidth: '100%' },
                        '& .captcha': { m: 1, mt: 2 },
                        // '& .submitbtn': { m: 1 },
                        '& .heading': { m: 1, textAlign: 'center' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className="form">
                        <div style={{ flexWrap: 'wrap', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <p
                                className="heading"
                                style={{ maxWidth: '700px', marginBottom: '12px', fontSize: '17px', fontFamily: 'Open Sans' }}
                            >
                                {/* If you are interested in English tuition for your child, please complete the expression of interest form
                                below, and we will get in touch with you as soon as possible. Or please email us at{' '}
                                <a href="mailto:hello@englishteacher.co.uk" className="contact-link-style">
                                    hello@englishteacher.co.uk{' '}
                                </a> */}
                                {joinTeam?.description}
                            </p>
                        </div>
                        <hr style={{ width: '98%' }} />

                        <div style={{ marginTop: '20px' }}>
                            <CssTextField
                                onMouseOver={{ borderColor: '#FAECF5' }}
                                required
                                id="custom-css-outlined-input"
                                label="Your Full Name"
                                type="text"
                                value={child?.fullName}
                                onChange={(e) => setChild({ ...child, fullName: e.target.value })}
                            />
                            <CssTextField
                                required
                                id="custom-css-outlined-input"
                                label="Your Email"
                                type="text"
                                value={child?.email}
                                // onChange={(e) => validateEmail(e.target.value)}
                                onChange={(e) => setChild({ ...child, email: e.target.value })}
                            />
                        </div>

                        <div>
                            <CssTextField
                                id="custom-css-outlined-input"
                                label="Telephone (optional)"
                                type="number"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12);
                                }}
                                pattern="^[0-9]{1,10}$"
                                value={child?.telephone}
                                onChange={(e) => setChild({ ...child, telephone: e.target.value })}
                            />

                            <span>
                                <span className="span-risponsice-css" style={{ marginLeft: '10px', marginTop: '10px', fontSize: '14px' }}>
                                    Upload CV (optional):
                                </span>
                                <FileBase
                                    type="file"
                                    id="images"
                                    accept="image/png, image/jpg, image/jpeg,application/pdf"
                                    // accept="application/pdf"
                                    multiple={false}
                                    onDone={({ base64 }) => setChild({ ...child, cvFile: base64 })}
                                />
                            </span>
                        </div>

                        <div>
                            <CssTextField
                                id="custom-css-outlined-input"
                                label="Description"
                                // placeholder="Any additional information you would like to share with me? E.g.: what they enjoy/ find difficult in English/ if GCSE which exam board? Or why you would like your child to have additional support?"
                                multiline
                                maxRows={12}
                                minRows={6}
                                className="text-field"
                                value={child?.description}
                                onChange={(e) => setChild({ ...child, description: e.target.value })}
                            />
                            {/* <span className="submitbtn" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}> */}

                            <span className="submitbtn-inside">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ backgroundColor: '#0099FF', ':hover': { bgcolor: '#0099FFD4' } }}
                                >
                                    Submit
                                </Button>
                            </span>
                            {/* </span> */}
                        </div>

                        <div className="captcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LcCtQolAAAAAHVYh5FmIVHzuyFPZ_PSrPxhS35F"
                                onChange={onCaptchaChange}
                            />
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export default JoinTeam;

const useStyles = makeStyles(() => ({
    centerText: {
        textAlign: 'center',
    },
}));
