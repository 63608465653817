import React from 'react';
import './footer.css';
const Footer = () => {
    var year = new Date().getFullYear();

    return (
        <div className="footer">
            <p>Copyright © 2014-{year} English Teacher. All rights reserved.</p>
        </div>
    );
};

export default Footer;
