import React from 'react';
import Home from './Home/Home';
import Classes from './Classes/Classes';
import Timetable from './Timetable/Timetable';
import Courses from './Courses/Courses';
import Resources from './Resources/Resources';
import Testimonials from './Testimonials/Testimonials';
import Team from './Team/Team';
// import Contact from './Contact/Contact';
import { Routes, Route } from 'react-router-dom';
import ResourceDetailsCard from './Resources/DetailsCard/ResourceDetailsCard';
import ClassDetailsCard from './Classes/DetailsCard/ClassDetailsCard';
import CourseDetailsCard from './Courses/DetailsCard/CourseDetailsCard';
import TeamDetailsCard from './Team/DetailsCard/TeamDetailsCard';
//data
// import { ResourcesData } from './Resources/Resources'
// import { ClassesData } from './Classes/Classes';
// import { CoursesData } from './Courses/Courses';
import ChildProfileForm from './ChildProfileForm/ChildProfileForm';
import ClassDetailsTimetable from './Timetable/ClassDetailsTimetable/ClassDetailsTimetable';
import AboutUs from './AboutUs';
import Faq from './FAQ';
import JoinTeam from './JoinTeam/JoinTeam';
import Gallery from './Gallery/Gallery';
import SuccessPage from './SuccessPage/SuccessPage';

// import axios from 'axios';

// import { API_URL } from '../api';

const Routing = ({
    ClassesData,
    CoursesData,
    ResourcesData,
    TeachersData,
    classTimetable,
    TestimonialsData,
    SettingsData,
    TimeTableTitleData,
    AboutUsData,
    faqDetailsData,
    faqData,
    GalleryData,
    teamPageDetails,
}) => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/thank-you" element={<SuccessPage />} />
                <Route exact path="/classes" element={<Classes ClassesData={ClassesData} />} />
                <Route
                    path="/timetable"
                    element={
                        <Timetable classTimetable={classTimetable} SettingsData={SettingsData} timeTableTitleData={TimeTableTitleData} />
                    }
                />
                <Route path="/courses" element={<Courses CoursesData={CoursesData} />} />
                <Route path="/gallery" element={<Gallery ClassesData={GalleryData} />} />
                <Route path="/resources" element={<Resources ResourcesData={ResourcesData} />} />
                <Route path="/testimonials" element={<Testimonials TestimonialsData={TestimonialsData} />} />
                <Route path="/team" element={<Team TeachersData={TeachersData} teamPageDetails={teamPageDetails} />} />
                {/* <Route path="/contact" element={<Contact />} /> */}
                <Route path="/contact-us" element={<ChildProfileForm />} />
                <Route path="/join-team" element={<JoinTeam />} />
                <Route path="/about-us" element={<AboutUs AboutUsData={AboutUsData} />} />
                <Route path="/faq" element={<Faq faqDetailsData={faqDetailsData} faqData={faqData} />} />
                {/* <Route path="/about-banne-demo" element={<AboutBannerDemo faqDetailsData={faqDetailsData} />} /> */}
                {ResourcesData.map((d, key) => {
                    return <Route key={key} path={`/resources/${d?._id}`} element={<ResourceDetailsCard resource={d} />} />;
                })}

                {ClassesData.map((c, key) => {
                    return <Route key={key} path={`/classes/${c?._id}`} element={<ClassDetailsCard c={c} />} />;
                })}

                {ClassesData.map((c, key) => {
                    return <Route key={key} path={`/classes/details/${c?.title}`} element={<ClassDetailsTimetable c={c} />} />;
                })}

                {CoursesData.map((c, key) => {
                    return <Route key={key} path={`/courses/${c?._id}`} element={<CourseDetailsCard course={c} />} />;
                })}

                {TeachersData.map((t, key) => {
                    return <Route key={key} path={`/team/${t?._id}`} element={<TeamDetailsCard team={t} />} />;
                })}
            </Routes>
        </div>
    );
};

export default Routing;
