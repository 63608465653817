import React, { useState } from 'react';
import finalLogo from '../../assets/finalLogo.png';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div className="navbar" id="navbar">
            <div className="logo" id="logo">
                <Link to="/">
                    <img src={finalLogo} height={74} alt="Logo" />
                </Link>
            </div>

            <div className="nav-container">
                <ul className="top-nav-container">
                    <li>
                        <Link className="pink" to="/about-us">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/team">
                            Meet the Team
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/join-team">
                            Join the Team
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/faq">
                            FAQ
                        </Link>
                    </li>

                    <li>
                        <Link className="pink" to="/contact-us">
                            Contact Us
                        </Link>
                    </li>
                </ul>
                <ul className="bottom-nav-container">
                    <li>
                        <Link className="pink" to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/classes">
                            Classes
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/timetable">
                            Timetable
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/courses">
                            Courses
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/resources">
                            Resources
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/testimonials">
                            Testimonials
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/gallery">
                            Gallery
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Mobile menu  */}
            <div className={'right ' + (menuOpen && 'active')}>
                <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
                    <span className="line1"></span>
                    <span className="line2"></span>
                    <span className="line3"></span>
                </div>
            </div>

            <div className={'menu ' + (menuOpen && 'active')}>
                <ul>
                    <li>
                        <Link className="pink" to="/" onClick={() => setMenuOpen(!menuOpen)}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/about-us" onClick={() => setMenuOpen(!menuOpen)}>
                            About us
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/team" onClick={() => setMenuOpen(!menuOpen)}>
                            Meet the Team
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/join-team" onClick={() => setMenuOpen(!menuOpen)}>
                            Join the Team
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/classes" onClick={() => setMenuOpen(!menuOpen)}>
                            Classes
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/timetable" onClick={() => setMenuOpen(!menuOpen)}>
                            Timetable
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/courses" onClick={() => setMenuOpen(!menuOpen)}>
                            Courses
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/resources" onClick={() => setMenuOpen(!menuOpen)}>
                            Resources
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/testimonials" onClick={() => setMenuOpen(!menuOpen)}>
                            Testimonials
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/gallery" onClick={() => setMenuOpen(!menuOpen)}> 
                            Gallery
                        </Link>
                    </li>
                    {/* <li>
                        <Link className="pink" to="/contact-us" onClick={() => setMenuOpen(!menuOpen)}>
                            Child Profile Form
                        </Link>
                    </li> */}
                    <li>
                        <Link className="pink" to="/faq" onClick={() => setMenuOpen(!menuOpen)}>
                            FAQ
                        </Link>
                    </li>
                    <li>
                        <Link className="pink" to="/contact-us" onClick={() => setMenuOpen(!menuOpen)}>
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
