import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import './timetable.css';
import TimetableCard from './Card/TimetableCard';
import SmTimeTableCard from './Card/SmTimeTableCard';
import { CircularProgress } from '@mui/material';
const Timetable = ({ classTimetable, SettingsData, timeTableTitleData }) => {
    const [timetableTitle, setTimetableTitle] = useState('Timetable of Classes');
    const [subTitle, setSubTitle] = useState('Timetable of Classes');
    useEffect(() => {
        if (timeTableTitleData?.length > 0) {
            if (timeTableTitleData[0]?.title) setTimetableTitle(timeTableTitleData[0]?.title);
            if (timeTableTitleData[0]?.subTitle) setSubTitle(timeTableTitleData[0]?.subTitle);
        }
    }, [timeTableTitleData]);

    return (
        <>
            <div className="timetable">
                <div className="page-heading">
                    <h1 style={{ marginBottom: 0 }}>{timetableTitle}</h1>
                </div>
                <div className="page-heading">
                    <p style={{ maxWidth: '700px', marginBottom: '12px', fontSize: '17px', fontFamily: 'Open Sans' }}>{subTitle}</p>
                </div>
                <div className="timetable-container timetable-display-table">
                    <TimetableCard timetable={classTimetable} />
                </div>
            </div>

            <div className="timetable-container timetable-display-card">
                {classTimetable?.length === 0 ? (
                    <CircularProgress />
                ) : (
                    classTimetable?.map((r, key) => (
                        <Fragment key={key}>
                            <SmTimeTableCard timetable={r} />
                        </Fragment>
                    ))
                )}
            </div>
        </>
    );
};

export default Timetable;
