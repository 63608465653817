import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import './courseDetailsCard.css';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactHtmlParser from 'react-html-parser';
const CourseDetailsCard = ({ course }) => {

    const [open, setOpen] = React.useState(false);
    const [from, setFrom] = React.useState({
        courseName: "",
        parentName: "",
        parentEmail: "",
        childFullName: "",
        yourGroup: "",
        shoolName: ""
    });

    const handleClickOpen = () => {
        setFrom({ ...from, courseName: course.title, parentName: "", parentEmail: "", childFullName: "", yourGroup: "", shoolName: "" })
        setOpen(true);

    };

    const handleClose = () => {
        localStorage.removeItem("userData")
        if (from.courseName === "") {
            return
        } else if (from.parentName === "") {
            return
        } else if (from.parentEmail === "") {
            return
        } else if (from.childFullName === "") {
            return
        }
        let data = {
            course_name: from.courseName,
            parent_name: from.parentName,
            parent_email: from.parentEmail,
            childfull_name: from.childFullName,
            your_group: from.yourGroup,
            shool_name: from.shoolName,
            plan_price: course.cost,
            course_data: course.date,
            course_time: course.time,
            course_location: course.location,
        }
        localStorage.setItem("userData", JSON.stringify(data))
        // axios
        //     .post(`${'http://localhost:8800'}/api/join-courses`, data)
        //     .then((res) => {
        //         // alert('Your message has been sent');


        //     })
        setOpen(false);
        // window.location.href = 'https://buy.stripe.com/test_aEU28H1Eq1Hs9os004'
        window.location.href = course.bookinglink

    };
    const handleCloseFrom = () => {

        setOpen(false);
    };
    return (
        <div className="course-details-container">
            <Card className="left-card">
                <CardMedia component="img" height="340" image={course?.img?.url} alt={course.title} />
                <CardContent sx={{ padding: '20px 40px' }}>
                    <Typography
                        variant="h4"
                        component="div"
                        style={{
                            textTransform: 'uppercase',
                            fontFamily: 'Open Sans',
                            fontWeight: '700',
                            padding: '16px 0',
                            fontSize: '30px',
                        }}
                    >
                        {course?.title}
                    </Typography>
                    {/* <Typography variant="body2" color="#4B5161" minHeight="80px" fontFamily="Open Sans" fontSize="17px">
                        {ReactHtmlParser(course?.description)}
                    </Typography> */}
                    <Typography variant="body2" color="#4B5161" minHeight="80px" fontFamily="Open Sans" fontSize="17px">
                        {ReactHtmlParser(course?.longDescription)}
                    </Typography>
                </CardContent>
            </Card>

            <div className="right-card-course">
                <Card>
                    <CardContent className="right-card-content" sx={{ p: 5 }}>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            color="black"
                            style={{ textTransform: 'uppercase', fontWeight: 'bold', padding: '12px 0', fontFamily: 'Open Sans' }}
                        >
                            Course Details
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            color="#000"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="bold"
                            marginBottom="0px"
                        >
                            Name of Course:
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#4B5161"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="medium"
                            marginBottom="10px"
                        >
                            {course.title}
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            color="#000"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="bold"
                            marginBottom="0px"
                        >
                            Date of Course:
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#4B5161"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="medium"
                            marginBottom="10px"
                        >
                            {course.date}
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            color="#000"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="bold"
                            marginBottom="0px"
                        >
                            Course Time:
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#4B5161"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="medium"
                            marginBottom="10px"
                        >
                            {course.time}
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            color="#000"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="bold"
                            marginBottom="0px"
                        >
                            Course Fee:
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#4B5161"
                            fontSize="18px"
                            fontFamily="Open Sans"
                            fontWeight="medium"
                            marginBottom="10px"
                        >
                            {course.cost}
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            color="#000"
                            fontFamily="Open Sans"
                            fontSize="18px"
                            fontWeight="bold"
                            marginBottom="0px"
                        >
                            Location:
                        </Typography>
                        <Typography variant="body2" color="#4B5161" fontFamily="Open Sans" fontSize="18px" fontWeight="medium">
                            {course.location}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ p: 5, pt: 0 }}>
                        {/* <a href={course.bookinglink}> */}
                        {/* <a href="https://buy.stripe.com/test_00gaFd1Eqcm67gk28b" > */}
                        <Button
                            id="payBtn"
                            size="small"
                            style={{
                                padding: '5px 15px',
                                backgroundColor: '#73D5F0',
                                color: 'white',
                                fontFamily: 'Open Sans',
                                fontSize: '18px',
                            }}
                            onClick={handleClickOpen}
                        >
                            PAY NOW
                        </Button>
                        {/* </a> */}
                    </CardActions>
                </Card>
                <Link
                    to="/contact-us"
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                    }}
                >
                    <Button
                        id="contact-classbtn"
                        size="small"
                        style={{
                            marginTop: '16px',
                            color: 'white',
                            background: `#FF5964`,
                            fontFamily: 'Open Sans',
                            fontWeight: 'bold',
                            fontSize: '18px',
                        }}
                    >
                        Contact Us
                    </Button>
                </Link>
            </div>
            <Dialog open={open} onClose={handleClose} fullWidth={true}
                maxWidth={"md"}>
                <DialogTitle>Buy {course.title}</DialogTitle>
                <DialogContent>
                    <br />
                    <TextField
                        autoFocus
                        required
                        id="custom-css-outlined-input"
                        label="Course name"
                        type="text"
                        fullWidth
                        value={from?.courseName}
                        onChange={(e) => setFrom({ ...from, courseName: e.target.value })}
                        style={{ marginBottom: 15 }}
                    />

                    <TextField
                        autoFocus
                        required
                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Full Name"
                        type="text"
                        fullWidth
                        value={from?.parentName}
                        onChange={(e) => setFrom({ ...from, parentName: e.target.value })}
                    />
                    <TextField
                        autoFocus
                        required
                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Email"
                        type="text"
                        fullWidth
                        value={from?.parentEmail}
                        onChange={(e) => setFrom({ ...from, parentEmail: e.target.value })}
                    />
                    <TextField
                        autoFocus
                        required
                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Child's full name attending the course"
                        type="text"
                        fullWidth
                        value={from?.childFullName}
                        onChange={(e) => setFrom({ ...from, childFullName: e.target.value })}
                    />
                    <TextField
                        autoFocus

                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Year group (optional)"
                        type="text"
                        fullWidth
                        value={from?.yourGroup}
                        onChange={(e) => setFrom({ ...from, yourGroup: e.target.value })}
                    />
                    <TextField
                        autoFocus

                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="School name (optional)"
                        type="text"
                        fullWidth
                        value={from?.shoolName}
                        onChange={(e) => setFrom({ ...from, shoolName: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFrom} id="payBtn"
                        size="small"
                        style={{
                            padding: '5px 15px',
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                        }}>Cancel</Button>
                    <Button onClick={handleClose} id="payBtn"
                        size="small"
                        style={{
                            padding: '5px 15px',
                            backgroundColor: '#73D5F0',
                            color: 'white',
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                        }}>Submit</Button>

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CourseDetailsCard;
