import React, { useEffect, useState } from 'react'
import './SuccessPage.css'
import axios from 'axios';
import { API_URL } from '../../api';
const SuccessPage = () => {
  const [planName, setPlanName] = useState(0)
  useEffect(() => {
    if (localStorage.getItem("userData") && localStorage.getItem("userData") !== "") {
      let from = JSON.parse(localStorage.getItem("userData"))
      setPlanName(parseFloat(from.plan_price))
      let data = {
        course_name: from.course_name,
        parent_name: from.parent_name,
        parent_email: from.parent_email,
        childfull_name: from.childfull_name,
        your_group: from.your_group,
        shool_name: from.shool_name,
        plan_price: from.plan_price,
        course_data: from.course_data,
        course_time: from.course_time,
        course_location: from.course_location,
        subjectStatus: "english"
      }
      axios
        .post(`${API_URL}/api/join-courses`, data)
        .then((res) => {
          localStorage.removeItem("userData")
        })
        .catch((error) => console.log(error));
    }
    else if (localStorage.getItem("userClassDetails") && localStorage.getItem("userClassDetails") !== "") {
      let from = JSON.parse(localStorage.getItem("userClassDetails"))
      setPlanName(parseFloat(from.plan_price))
      let data = {
        course_name: from.course_name,
        parent_name: from.parent_name,
        parent_email: from.parent_email,
        childfull_name: from.childfull_name,
        your_group: from.your_group,
        shool_name: from.shool_name,
        plan_price: from.plan_price
      }
      axios
        .post(`${API_URL}/api/join-courses`, data)
        .then((res) => {
          localStorage.removeItem("userClassDetails")
        })
        .catch((error) => console.log(error));
    }
  }, []);
  return (

    <>
      <div className='thanks-message-wrapper'>
        <div class="card">
          <div className='div_class'>
            <i class="checkmark">✓</i>
          </div>
          <h1>Thank you for your payment to <a href='https://englishteacher.co.uk'>englishteacher.co.uk.</a> </h1>
          <p>You will also receive an email with regards to the couse details.</p>
          <div className='thankyou-payment-grid'>
            <div className='thankyou-payment-6'>
              <p>englishteacher.co.uk</p>
            </div>
            <div className='thankyou-payment-6'>
              <h4>{planName}</h4>
            </div>
          </div>
        </div>


      </div>
    </>
  )
}

export default SuccessPage