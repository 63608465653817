import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function SmTimeTableCard(props) {
    return (
        <Card sx={{ width: 300, margin: '10px' }}>
            <CardContent style={{ borderTop: `30px solid #73D5F0`, borderBottom: '1px solid #D3D3D3', paddingBottom: '16px' }}>
                <Typography
                    minHeight="30px"
                    variant="h6"
                    component="div"
                    sx={{ fontFamily: 'Open Sans', fontSize: '18px', fontWeight: '600' }}
                >
                    Tutoring Class: {props.timetable.tutoringClass}
                </Typography>
                <Typography
                    minHeight="30px"
                    variant="h6"
                    component="div"
                    sx={{ fontFamily: 'Open Sans', fontSize: '18px', fontWeight: '600' }}
                >
                    Day: {props.timetable.day}
                </Typography>
                <Typography
                    minHeight="30px"
                    variant="h6"
                    component="div"
                    sx={{ fontFamily: 'Open Sans', fontSize: '18px', fontWeight: '600' }}
                >
                    Time: {props.timetable.time}
                </Typography>
            </CardContent>
        </Card>
    );
}
