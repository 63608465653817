import { CircularProgress } from '@mui/material';
import React, { Fragment } from 'react';
import CardTeam from './Card/CardTeam';
import './team.css';

const Team = ({ TeachersData, teamPageDetails }) => {
    return (
        <div className="team">
            <div className="about-heading-box">
                <div className="page-heading">
                    <h1>Meet Our Team</h1>
                </div>
                <div className="page-heading">
                    <p style={{ maxWidth: '700px', marginTop: '6px', marginBottom: '12px', fontSize: '17px', fontFamily: 'Open Sans' }}>
                        {teamPageDetails?.description}
                    </p>
                </div>
            </div>

            <div className="team-container">
                {TeachersData?.length === 0 ? (
                    <CircularProgress />
                ) : (
                    TeachersData?.map((t, key) => (
                        <Fragment key={key} className="main-container">
                            <CardTeam teacher={t} />
                        </Fragment>
                    ))
                )}
            </div>
        </div>
    );
};

export default Team;
