import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './classDetailsCard.css';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactHtmlParser from 'react-html-parser';
import InputAdornment from '@mui/material/InputAdornment';
const ClassDetailsCard = ({ c }) => {
    const [open, setOpen] = React.useState(false);
    const [from, setFrom] = React.useState({
        courseName: "",
        parentName: "",
        parentEmail: "",
        childFullName: "",
        yourGroup: "",
        shoolName: ""
    });

    const handleClickOpen = () => {
        setFrom({ ...from, courseName: c.title, parentName: "", parentEmail: "", childFullName: "", yourGroup: "", shoolName: "" })
        setOpen(true);

    };

    const handleClose = () => {
        localStorage.removeItem("userClassDetails")
        if (from.courseName === "") {
            return
        } else if (from.parentName === "") {
            return
        } else if (from.parentEmail === "") {
            return
        } else if (from.childFullName === "") {
            return
        }
        let data = {
            course_name: from.courseName,
            parent_name: from.parentName,
            parent_email: from.parentEmail,
            childfull_name: from.childFullName,
            your_group: from.yourGroup,
            shool_name: from.shoolName,
            plan_price: c?.pricing[0]?.price
        }
        localStorage.setItem("userClassDetails", JSON.stringify(data))
        // axios
        //     .post(`${'http://localhost:8800'}/api/join-courses`, data)
        //     .then((res) => {
        //         // alert('Your message has been sent');


        //     })
        setOpen(false);
        window.location.href = c.bookinglink

    };
    const handleCloseFrom = () => {

        setOpen(false);
    };
    return (
        <div className="class-details-container">
            <Card className="left-card">
                <CardMedia component="img" height="340" image={c.img?.url} alt={c?.title} />
                <CardContent sx={{ padding: '10px 30px' }}>
                    <Typography
                        variant="h4"
                        component="div"
                        style={{
                            fontFamily: 'Open Sans',
                            textTransform: 'uppercase',
                            fontSize: '30px',
                            fontWeight: '700',
                            padding: '16px 0',
                        }}
                    >
                        {c.title}
                    </Typography>
                    {/* <Typography variant="body2" color="#4B5161" minHeight="80px" fontSize="17px" fontFamily="Open Sans">
                        {ReactHtmlParser(c.description)}
                    </Typography> */}
                    <Typography variant="body2" color="#4B5161" minHeight="80px" fontSize="17px" fontFamily="Open Sans">
                        {ReactHtmlParser(c.details)}
                    </Typography>
                </CardContent>
            </Card>
            <div className="right-card">
                <Card className="hoursCard">
                    <Typography
                        className="right-card-heading"
                        gutterBottom
                        variant="h5"
                        color="white"
                        backgroundColor="#73D5F0"
                        component="div"
                        fontFamily="Open Sans"
                        fontSize="18px"
                        fontWeight="bold"
                    >
                        Class Hours
                    </Typography>

                    {c.classHours.map((hour, key) => (
                        <div className="content-container" key={key}>
                            <CardContent className="details-content">
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    color="#4B5161"
                                    component="div"
                                    sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}
                                >
                                    {hour.days}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    color="#cf571b"
                                    component="div"
                                    sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}
                                >
                                    {hour.time}
                                </Typography>
                            </CardContent>
                        </div>
                    ))}
                </Card>

                <Card className="pricingCard">
                    <Typography
                        className="right-card-heading"
                        gutterBottom
                        variant="h5"
                        color="white"
                        backgroundColor="#FFA937"
                        component="div"
                        fontFamily="Open Sans"
                        fontSize="18px"
                        fontWeight="bold"
                    >
                        Pricing
                    </Typography>

                    {c.pricing.map((p, key) => (
                        <div className="content-container" key={key}>
                            <CardContent className="details-content">
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    color="#4B5161"
                                    component="div"
                                    sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}
                                >
                                    {p.classname}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    color="#cf571b"
                                    component="div"
                                    sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}
                                >
                                    {p.price}
                                </Typography>
                            </CardContent>
                        </div>
                    ))}
                </Card>
                {c?.bookinglink !== "" ? <Button
                    id="contact-classbtn"
                    size="small"
                    style={{
                        marginTop: '16px',
                        color: 'white',
                        marginLeft: "-4px",
                        background: `#FF5964`,
                        fontFamily: 'Open Sans',
                        fontWeight: 'bold',
                        fontSize: '18px',
                    }}
                    onClick={handleClickOpen}
                >
                    PAY NOW
                </Button> : null}

                <Link
                    to="/contact-us"
                    style={{
                        width: '100%',
                        // display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                    }}
                >

                    <Button
                        id="contact-classbtn"
                        size="small"
                        style={{
                            marginTop: '16px',
                            color: 'white',
                            marginLeft: "4px",
                            background: `#FF5964`,
                            fontFamily: 'Open Sans',
                            fontWeight: 'bold',
                            fontSize: '18px',
                        }}
                    >
                        Contact Us
                    </Button>
                </Link>
            </div>
            <Dialog open={open} onClose={handleClose} fullWidth={true}
                maxWidth={"md"}>
                <DialogTitle>Buy {c.title}</DialogTitle>
                <DialogContent>
                    <br />
                    <TextField
                        autoFocus
                        required
                        id="custom-css-outlined-input"
                        label="Course name"
                        type="text"
                        fullWidth
                        value={from?.courseName}
                        onChange={(e) => setFrom({ ...from, courseName: e.target.value })}
                        style={{ marginBottom: 15 }}
                    />
                    <div style={{ display: "flex", }}>
                        <TextField
                            autoFocus
                            disabled
                            id="custom-css-outlined-input"
                            label="Payment details"
                            // sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
                            // type="text"
                            // fullWidth
                            value={c?.firstTermClass}
                            // onChange={(e) => setFrom({ ...from, courseName: e.target.value })}
                            style={{ marginBottom: 15, width: '50%' }}
                        />
                        <div style={{ paddingLeft: "10px", width: '50%' }}>
                            <TextField
                                autoFocus
                                id="custom-css-outlined-input"
                                disabled
                                label="Price"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                  }}
                                // sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
                                // type="text"
                                // fullWidth
                                value={c?.termPrice}
                                // onChange={(e) => setFrom({ ...from, courseName: e.target.value })}
                                style={{ marginBottom: 15, width: '100%' }}
                            />
                        </div>
                    </div>
                    <TextField
                        autoFocus
                        required
                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Full Name"
                        type="text"
                        fullWidth
                        value={from?.parentName}
                        onChange={(e) => setFrom({ ...from, parentName: e.target.value })}
                    />
                    <TextField
                        autoFocus
                        required
                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Email"
                        type="text"
                        fullWidth
                        value={from?.parentEmail}
                        onChange={(e) => setFrom({ ...from, parentEmail: e.target.value })}
                    />
                    <TextField
                        autoFocus
                        required
                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Child's full name attending the course"
                        type="text"
                        fullWidth
                        value={from?.childFullName}
                        onChange={(e) => setFrom({ ...from, childFullName: e.target.value })}
                    />
                    <TextField
                        autoFocus

                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="Year group (optional)"
                        type="text"
                        fullWidth
                        value={from?.yourGroup}
                        onChange={(e) => setFrom({ ...from, yourGroup: e.target.value })}
                    />
                    <TextField
                        autoFocus

                        style={{ marginBottom: 15 }}
                        id="custom-css-outlined-input"
                        label="School name (optional)"
                        type="text"
                        fullWidth
                        value={from?.shoolName}
                        onChange={(e) => setFrom({ ...from, shoolName: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFrom} id="payBtn"
                        size="small"
                        style={{
                            padding: '5px 15px',
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                        }}>Cancel</Button>
                    <Button onClick={handleClose} id="payBtn"
                        size="small"
                        style={{
                            padding: '5px 15px',
                            backgroundColor: '#73D5F0',
                            color: 'white',
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                        }}>Submit</Button>

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ClassDetailsCard;
