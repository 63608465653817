import React from 'react';
import './childprofileform.css';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';

import { styled } from '@mui/material/styles';
import axios from 'axios';
import { API_URL } from '../../api';
import { useNavigate } from 'react-router-dom';
import { BorderAll } from '@mui/icons-material';
import { hover } from '@testing-library/user-event/dist/hover';
import Swal from 'sweetalert2';
import errorIcon from '../../assets/error-icon.png';
import successIcon from '../../assets/success-icon.png';

const recaptchaRef = React.createRef();

const CssTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: '#0099FF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0099FF',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0099FF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0099FF',
        },
    },
    // '& .MuiOutlinedInput-root:hover': {
    //     '& fieldset': {
    //         borderColor: '#FAECF5',
    //     },
    //     '&.Mui-focused fieldset': {
    //         borderColor: '#FAECF5',
    //     },
    // },

    minWidth: '95%',
    [theme.breakpoints.up('sm')]: {
        minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
        minWidth: '44ch',
    },
    [theme.breakpoints.up('lg')]: {
        minWidth: '50ch',
    },
}));

const enquiryoptions = [
    {
        value: 'ageneralenquiry',
        label: 'A General Enquiry',
    },
    {
        value: 'reception',
        label: 'Reception',
    },

    {
        value: 'year1',
        label: 'Year 1',
    },
    {
        value: 'year2',
        label: 'Year 2',
    },
    {
        value: 'year3',
        label: 'Year 3',
    },
    {
        value: 'year4',
        label: 'Year 4',
    },
    {
        value: 'year5',
        label: 'Year 5',
    },
    {
        value: 'year6',
        label: 'Year 6',
    },
    {
        value: 'year7',
        label: 'Year 7',
    },
    {
        value: 'year8',
        label: 'Year 8',
    },
    {
        value: 'year9',
        label: 'Year 9',
    },
    {
        value: 'year10',
        label: 'Year 10',
    },
    {
        value: 'year11',
        label: 'Year 11',
    },
    {
        value: 'year12',
        label: 'Year 12',
    },
    {
        value: 'year13',
        label: 'Year 13',
    },
];
const ChildProfileForm = () => {
    const navigate = useNavigate();

    const [child, setChild] = React.useState({
        parentName: '',
        email: '',
        childName: '',
        school: '',
        group: '',
        information: '',
        reference: '',
    });

    function onCaptchaChange(value) {
        // setVerified(true);
    }

    const createChild = (child) => {
        axios
            .post(`${API_URL}/api/child`, child)
            .then((res) => {
                // alert('Your message has been sent');
                Swal.fire({
                    // position: 'top-end',
                    imageUrl: 'https://pixlok.com/wp-content/uploads/2021/12/Green-Tick-Icon-SVG-03vd.png',
                    imageHeight: '100px',
                    title: 'Thank you for your message.',
                    html: 'I will respond to you as soon as I can.' + '<br/>' + '---Meera Vasudeva---',
                    // text: ' I will respond to you as soon as I can.   ---Meera Vasudeva---',
                    width: 600,
                    confirmButtonColor: '#0099FF',
                    customClass: {
                        icon: 'no-before-icon',
                    },
                });
                navigate('/');
            })
            // .catch((err) => alert('Your message could not be sent'));
            .catch((err) => {
                Swal.fire({
                    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flat_cross_icon.svg/512px-Flat_cross_icon.svg.png',
                    imageHeight: '100px',
                    title: 'Oops...',
                    text: 'Your message could not be sent!',
                    confirmButtonColor: '#0099FF',
                });
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        recaptchaRef.current.execute();
        createChild(child);
    };

    return (
        <div className="childprofile">
            <div className="page-heading">
                <h1>Contact Us</h1>
            </div>
            <div className="childprofile-container">
                <Box
                    component="form"
                    className="boxx"
                    onSubmit={handleSubmit}
                    sx={{
                        '& .MuiTextField-root': { m: 1, maxWidth: '100%' },
                        '& .captcha': { m: 1, mt: 2 },
                        '& .submitbtn': { m: 1, mt: 4 },
                        '& .heading': { m: 1, textAlign: 'center' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className="form">
                        <div style={{ flexWrap: 'wrap', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <p
                                className="heading"
                                style={{ maxWidth: '700px', marginBottom: '12px', fontSize: '17px', fontFamily: 'Open Sans' }}
                            >
                                If you are interested in English tuition for your child, please complete the expression of interest form
                                below, and we will get in touch with you as soon as possible. Or please email us at{' '}
                                <a href="mailto:hello@englishteacher.co.uk" className="contact-link-style">
                                    hello@englishteacher.co.uk{' '}
                                </a>
                            </p>
                        </div>
                        <hr style={{ width: '98%' }} />
                        <div className="childheading" style={{ margin: '5px' }}>
                            <h2>English Tuition- Expression of Interest 2022-23</h2>
                            <p>For English Tuition from September 2022</p>
                        </div>
                        <hr style={{ width: '98%' }} />
                        <div style={{ marginTop: '20px' }}>
                            <CssTextField
                                onMouseOver={{ borderColor: '#FAECF5' }}
                                required
                                id="custom-css-outlined-input"
                                label="Your Full Name"
                                type="text"
                                value={child?.parentName}
                                onChange={(e) => setChild({ ...child, parentName: e.target.value })}
                            />
                            <CssTextField
                                required
                                id="custom-css-outlined-input"
                                label="Your Email "
                                type="text"
                                value={child?.email}
                                onChange={(e) => setChild({ ...child, email: e.target.value })}
                            />
                        </div>
                        <div>
                            <div>
                                <CssTextField
                                    id="custom-css-outlined-input"
                                    label="Child Full Name"
                                    type="text"
                                    value={child?.childName}
                                    onChange={(e) => setChild({ ...child, childName: e.target.value })}
                                />
                                <CssTextField
                                    id="custom-css-outlined-input"
                                    label="Which school does your child currently attend?"
                                    type="text"
                                    value={child?.school}
                                    onChange={(e) => setChild({ ...child, school: e.target.value })}
                                />
                            </div>

                            <div></div>
                            <div className="fullwidth"></div>

                            <CssTextField
                                id="custom-css-outlined-input"
                                select
                                label="Which year group is your child currently in? "
                                value={child?.group}
                                onChange={(e) => setChild({ ...child, group: e.target.value })}
                            >
                                {enquiryoptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                            <CssTextField
                                required
                                id="custom-css-outlined-input"
                                label="How did you hear about us?"
                                type="text"
                                value={child?.reference}
                                onChange={(e) => setChild({ ...child, reference: e.target.value })}
                            />
                        </div>

                        <div>
                            <CssTextField
                                id="custom-css-outlined-input"
                                required
                                label="Any additional information you would like to share with me?"
                                placeholder="Any additional information you would like to share with me? E.g.: what they enjoy/ find difficult in English/ if GCSE which exam board? Or why you would like your child to have additional support?"
                                multiline
                                maxRows={12}
                                minRows={6}
                                className="text-field"
                                value={child?.information}
                                onChange={(e) => setChild({ ...child, information: e.target.value })}
                            />
                            <span className="submitbtn-inside">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ backgroundColor: '#0099FF', ':hover': { bgcolor: '#0099FFD4' } }}
                                >
                                    Submit
                                </Button>
                            </span>
                        </div>

                        {/* <div
                            className="submitbtn"
                            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: '0px' }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ backgroundColor: '#0099FF', ':hover': { bgcolor: '#0099FFD4' } }}
                            >
                                Submit 123
                            </Button>
                        </div> */}
                        <div className="captcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LcCtQolAAAAAHVYh5FmIVHzuyFPZ_PSrPxhS35F"
                                onChange={onCaptchaChange}
                            />
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export default ChildProfileForm;
