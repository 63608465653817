import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './classDetailsCard.css'
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


const ClassDetailsTimetable = ({ c }) => {
  return (

    <div className='class-details-container'>
      <Card className='left-card'>
        <CardMedia
          component="img"
          height="340"
          image={c.img}
          alt={c.title}
        />
        <CardContent sx={{padding:"10px 30px"}}>
          <Typography variant="h4" component="div" style={{ fontFamily: 'Open Sans', textTransform: "uppercase", fontSize: "30px", fontWeight: "700", padding: "16px 0" }}>
            {c.title}
          </Typography>
          <Typography variant="body2" color="#4B5161" minHeight="80px" fontSize="17px" fontFamily='Open Sans'>
            {ReactHtmlParser(c.description)}
          </Typography>
        </CardContent>

      </Card>
      <div className="right-card" >
        <Card className="hoursCard">
          <Typography className="right-card-heading" gutterBottom variant="h5" color="white" backgroundColor="#73D5F0" component="div" fontFamily='Open Sans' fontSize='18px' fontWeight='bold' >
            Class Hours
          </Typography>

          {c.classHours.map((hour, key) => (
            <div className='content-container' key={key}>
              <CardContent className='details-content'>
                <Typography gutterBottom variant="h5" color="#4B5161" component="div" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}>
                  {hour.days}
                </Typography>
                <Typography gutterBottom variant="h5" color="#cf571b" component="div" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}>
                  {hour.time}
                </Typography>

              </CardContent>
            </div>
          ))}

        </Card>

        <Card className="pricingCard">
          <Typography className="right-card-heading" gutterBottom variant="h5" color="white" backgroundColor="#FFA937" component="div" fontFamily='Open Sans' fontSize='18px' fontWeight='bold' >
            Pricing
          </Typography>

          {c.pricing.map((p, key) => (
          <div className='content-container'>
            <CardContent className='details-content'>
              <Typography gutterBottom variant="h5" color="#4B5161" component="div" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}>
               {p.classname}
              </Typography>
              <Typography gutterBottom variant="h5" color="#cf571b" component="div" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}>
               {p.price}
              </Typography>

            </CardContent>
          </div>
          ))}
        </Card>


        <Link to="/contact" style={{ width: "100%", display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}>
          <Button id="contact-classbtn" size="small" style={{ marginTop: "16px", color: 'white', background: `#FF5964`, fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '18px' }}>
            Contact Us</Button>
        </Link>

      </div>
    </div>
  )
}

export default ClassDetailsTimetable