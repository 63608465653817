import React from 'react';
import './component/AboutUs.css';
// import { convert } from 'html-to-text';
import ReactHtmlParser from 'react-html-parser';
const About = ({ AboutUsData }) => {
    return (
        <>
            {/* <div className="about-banner-section">
                <img src={AboutUsData?.bannerImg?.url} alt="" />
                <div className="centered" style={{ color: AboutUsData.bannerColor }}>
                    About English Teacher
                </div>
            </div> */}
            <div className="about-container">
                <div className="about-heading-box">
                    <div className="page-heading">
                        <h1>About English Teacher</h1>
                    </div>
                </div>

                <div className="about-bottom-box">
                    <>
                        <div className="about-row">
                            <div className="about-left-col">
                                <img src={AboutUsData?.aboutImg?.url} alt="" className="about-left-img" />
                            </div>
                            <div className="about-right-col">
                                <h4>{AboutUsData?.heading}</h4>
                                <h2>{AboutUsData?.subHeading}</h2>
                                {ReactHtmlParser(AboutUsData?.paragraph)}
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};

export default About;
