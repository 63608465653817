import React, { Fragment } from 'react';
import './testimonials.css';
import CardTestimonial from './Card/CardTestimonial';
import CardActions from '@mui/material/CardActions';
import { Button } from '@mui/material';

const Testimonials = ({ TestimonialsData }) => {
    const perPageTestimonials = 8;
    const defaultPage = 16;
    const [noOfElements, setNoOfElements] = React.useState(defaultPage);
    const slice = TestimonialsData.slice(0, noOfElements);

    const LoadMore = () => {
        setNoOfElements(noOfElements + perPageTestimonials);
    };
    return (
        <div className="testimonials">
            <div className="page-heading">
                <h1> Testimonials</h1>
            </div>
            <div className="testimonials-container">
                {slice.map((t, key) => (
                    <Fragment key={key}>
                        <CardTestimonial test={t} />
                    </Fragment>
                ))}
            </div>
            {TestimonialsData.length <= noOfElements ? (
                ''
            ) : (
                <CardActions sx={{ p: 5, pt: 0 }}>
                    <Button
                        onClick={() => LoadMore()}
                        size="small"
                        className="Learn-more-hover"
                        style={{
                            marginTop: '30px',
                            padding: '5px 15px',
                            backgroundColor: '#73D5F0',
                            color: 'white',
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                        }}
                    >
                        Load More
                    </Button>
                </CardActions>
            )}
        </div>
    );
};

export default Testimonials;
