import { CircularProgress } from '@mui/material';
import React, { Fragment } from 'react';
import CardClass from './Card/CardClass';
import './classes.css';

const Classes = ({ ClassesData }) => {
    return (
        <div className="classes">
            <div className="page-heading">
                <h1>Small Group Classes</h1>
            </div>
            <div className="classes-container">
                {ClassesData?.length === 0 ? (
                    <CircularProgress />
                ) : (
                    ClassesData.map((c, key) => (
                        <Fragment key={key}>
                            <CardClass class={c} />
                        </Fragment>
                    ))
                )}
            </div>
        </div>
    );
};

export default Classes;
