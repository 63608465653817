import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#73D5F0',
        color: theme.palette.common.white,
        fontSize: 17,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        padding: '20px 20px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Open Sans',
        fontSize: 17,
        textTransform: 'capitalize',
        padding: '14px 20px',
        color: '#4B5161',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function TimetableCard({ timetable }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 850 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            style={{
                                fontFamily: 'Open Sans',
                                fontSize: '17px',
                                fontWeight: '300',
                                color: 'white',
                            }}
                        >
                            Tutoring Class
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            style={{
                                fontFamily: 'Open Sans',
                                fontSize: '17px',
                                fontWeight: '300',
                                color: 'white',
                            }}
                        >
                            Day
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            style={{
                                fontFamily: 'Open Sans',
                                fontSize: '17px',
                                fontWeight: '300',
                                color: 'white',
                            }}
                        >
                            Time
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">Details </StyledTableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timetable.map((t, key) => (
                        <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">
                                {t.tutoringClass}
                            </StyledTableCell>
                            <StyledTableCell align="center">{t.day}</StyledTableCell>
                            <StyledTableCell align="center">{t.time}</StyledTableCell>
                            {/* <StyledTableCell align="center">
                                <Link to={`/classes/details/${t.tutoringClass}`}>
                                    <Button
                                        size="small"
                                        sx={{ ':hover': { bgcolor: 'pink' } }}
                                        style={{
                                            width: '70%',
                                            fontFamily: 'Open Sans',
                                            fontSize: '17px',
                                            textTransform: 'capitalize',
                                            color: '#0099FF',
                                        }}
                                    >
                                        More Details
                                    </Button>
                                </Link>
                            </StyledTableCell> */}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
