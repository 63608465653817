import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import FsLightbox from 'fslightbox-react';
import { useState } from 'react';
import './CardGallery.css';
import ReactHtmlParser from 'react-html-parser';
export default function CardGallery(props) {
    const [toggler, setToggler] = useState();
    const handleToggle = () => {
        setToggler(!toggler);
    };
    return (
        <>
            {/*  <Card sx={{ maxWidth:244,height:376 , margin: 2 }} > */}

            <Card
                sx={{
                    width: {
                        md: 300,
                        lg: 300,
                    },
                    margin: 2,
                }}
                onClick={() => {
                    handleToggle();
                }}
                className="gallery-card"
            >
                <CardMedia
                    component="img"
                    image={props.test?.img?.url}
                    alt={props?.test?.title}
                    className='gallery-img'
                />
                {/* <CardActionArea> */}
                <CardContent className='gallery-content'>
                    <Typography
                        variant="h4"
                        color="black"
                        style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            paddingTop: '10px',
                        }}
                    >
                        {props?.test?.title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="black"
                        className='description'
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        {ReactHtmlParser(props?.test?.details)}
                    </Typography>
                </CardContent>


                {/* </CardActionArea> */}
            </Card>
            <FsLightbox toggler={toggler} sources={[props.test.img?.url]} />
        </>
    );
}

// export default function CardGallery(props) {
//   return (
//     <Card sx={{width: 300 , margin: 3}}>
//       <CardContent style={{ borderTop:`8px solid red`, borderBottom:"1px solid gray"}}>
//         <Typography variant="h6" component="div" sx={{fontFamily:'Open Sans', fontSize:"17px",fontWeight:'600'}}>
//         {/* {props.resource.title} */}
//         Black semi bold heading
//         </Typography>

//         <Typography minHeight="80px" variant="body2" component="div" sx={{marginTop:'12px', fontFamily:'Open Sans', fontSize:"17px"}}>
//         {/* {props.resource.title} */}
//         Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, quae?
//         </Typography>
//       </CardContent>

//       {/* <CardActions  sx={{ p:2, justifyContent:'flex-end'}}  >
//       <Link to={props.resource.link}>
//         <Button  size="small" style={{color:'#1976d2;',  fontFamily:'Open Sans', fontWeight:'bold', padding:"5px 24px", border:'1px solid #1976d2', borderRadius:'30px' }}> View</Button>
//         </Link>
//       </CardActions> */}

//     </Card>
//   );
// }
