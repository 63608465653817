import * as React from 'react';
import Button from '@mui/material/Button';
import './teamDetailsCard.css';
import { Link } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

const ClassDetailsCard = ({ team }) => {
    return (
        <div className="teamdetails">
            <Link to="/team">
                <Button sx={{ ':hover': { bgcolor: '#0099FFBF' }, backgroundColor: '#0099FF', color: 'white' }}>BACK</Button>
            </Link>
            <div className="details-container">
                <div className="left-avatar">
                    <img src={team?.img?.url} alt={team.name} />
                </div>

                <div className="right-details">
                    <h3>{team.name} </h3>
                    <hr />
                    <p id="lastP">{ReactHtmlParser(team.longDescription)}</p>
                </div>
            </div>
        </div>
    );
};

export default ClassDetailsCard;
