import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './cardclass.css';
import { Link } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

export default function CardClass(props) {
    return (
        <Card sx={{ maxWidth: 345, margin: 3, width: 400 }}>
            <CardMedia component="img" height="200" image={props.class.img?.url} alt={props.class.title} />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Open Sans', fontWeight: 'bold' }}>
                    {props.class.title}
                </Typography>
                <Typography variant="body2" color="#4B5161" sx={{ fontFamily: 'Open Sans', fontSize: '17px', height: 60 }}>
                    {ReactHtmlParser(props.class.description)}
                </Typography>
            </CardContent>
            <Link to={props?.class?._id}>
                <CardActions id="btnbg" sx={{ p: 0 }} className="card-btn" style={{ background: `${props.class.background}` }}>
                    <Button
                        id="classbtnn"
                        size="small"
                        style={{ color: 'white', fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '17px' }}
                    >
                        Details
                    </Button>
                </CardActions>
            </Link>
        </Card>
    );
}
