import React from 'react';
import './home.css';
import headerImg from '.././../assets/headerImg.png';

import instagramLogo from '../../assets/instagram-icon-grey.svg';
import FBLogo from '../../assets/facebook-icon-grey.svg';
import LinkedinLogo from '../../assets/linkedin-icon-grey.svg';

const Home = () => {
  return (
    <div className="header">
      <div className="header-image">
        <img src={headerImg} alt="" />
      </div>
    

      <div className="header-container">
      <div className="">
        <div className="logo-group">
          <div className='icondiv'>
          <a
            href="https://www.instagram.com/englishteacher.co.uk?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            className="hoverclass"
          >
            <img
              src={instagramLogo}
              className="instagramLogo"
              alt="Instagram "
            />
             </a>
          </div>
         

          <a
            href="https://www.facebook.com/www.englishteacher.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FBLogo} className="instagramLogo" alt="FBLogo " />
          </a>
        </div>
      </div>
        <div className="header-content">
          <h1>
            Specialist English
            <br />
            Tuition Centre
          </h1>
          <h2>Run by Qualified English Teachers</h2>
          <h4>Primary / 11+</h4>
          <h4>KS3</h4>
          <h4>GCSE</h4>
          <h4>A Level</h4>
        </div>
      </div>
      
    </div>
  );
};

export default Home;
