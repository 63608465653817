import React, { Fragment } from 'react';
import './Gallery.css';
import CardGallery from './Card/CardGallery';
import CardActions from '@mui/material/CardActions';
import { Button } from '@mui/material';

const Gallery = ({ ClassesData }) => {
    const perPagegallery = 8;
    const defaultPage = 16;
    const [noOfElements, setNoOfElements] = React.useState(defaultPage);
    const slice = ClassesData.slice(0, noOfElements);

    const LoadMore = () => {
        setNoOfElements(noOfElements + perPagegallery);
    };
    return (
        <div className="gallery">
            <div className="gallery-page-heading">
                <h1>Our Gallery</h1>
            </div>
            <div className="gallery-container">
                {slice.map((t, key) => (
                    <Fragment key={key}>
                        <CardGallery test={t} />
                    </Fragment>
                ))}
            </div>
            {ClassesData.length <= noOfElements ? (
                ''
            ) : (
                <CardActions sx={{ p: 5, pt: 0 }}>
                    <Button
                        onClick={() => LoadMore()}
                        size="small"
                        className="Learn-more-hover"
                        style={{
                            marginTop: '30px',
                            padding: '5px 15px',
                            backgroundColor: '#73D5F0',
                            color: 'white',
                            fontFamily: 'Open Sans',
                            fontSize: '18px',
                        }}
                    >
                        Load More
                    </Button>
                </CardActions>
            )}
        </div>
    );
};

export default Gallery;
