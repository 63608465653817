import * as React from 'react';
import './cardteam.css';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

export default function CardTeam(props) {
    return (
        <Link to={props?.teacher?._id} className="link">
            <div className="card-container">
                <div className="avatar">
                    <img src={props.teacher?.img?.url} alt={props.teacher.name} />
                </div>
                <div className="details">
                    <h3>{props.teacher.name} </h3>
                    <p>{ReactHtmlParser(props.teacher.frontDescription)}</p>
                </div>
            </div>
        </Link>
    );
}
