import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';

export default function CardCourse({ course }) {
    const [btnBackground, setBtnBackground] = React.useState('#73D5F0');

    const handleMouseIn = () => {
        setBtnBackground('#0099FFD1');
    };

    const handleMouseOut = () => {
        setBtnBackground('#0099FF');
    };

    return (
        <Card sx={{ margin: 3, width: 345 }}>
            <CardMedia component="img" height="200" image={course.img?.url} alt={course.title} />
            <CardContent sx={{ height: 100 }}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '1.2rem' }}
                >
                    {course.title}
                </Typography>
                <Typography variant="body2" color="#4B5161" sx={{ fontFamily: 'Open Sans', fontSize: '17px', height: 60 }}>
                    {ReactHtmlParser(course.description)}
                </Typography>
            </CardContent>
            <Link to={course?._id}>
                <CardActions id="btnbg" sx={{ p: 0 }} className="card-btn" style={{ background: `${btnBackground}` }}>
                    <Button
                        id="classbtnn"
                        size="small"
                        style={{ color: 'white', fontFamily: 'Open Sans', fontWeight: 'bold', fontSize: '17px' }}
                    >
                        FIND OUT MORE
                    </Button>
                </CardActions>
            </Link>
        </Card>
    );
}
