import React,{Fragment} from 'react'
import './resources.css'

import CardResource from './Card/CardResource'
import { CircularProgress } from '@mui/material'

const Resources = ({ResourcesData}) => {
  return (
    <div className="resources">
      
    <div className="page-heading">
     <h1>Recommended Resources</h1>
     </div>
  <div className="resources-container">
     
  {ResourcesData?.length === 0 ? <CircularProgress/> : 
      ResourcesData?.map((r, key) => (
      <Fragment key={key}>
        <CardResource resource={r} />
      </Fragment>
    ))
  }
  </div>
  </div>
  )
}

export default Resources